// File app/javascript/packs/scroll.js

const scroll = {
  init() {

    // click
    [].forEach.call(document.querySelectorAll('[data-behavior~=scroll-trigger]'), function(el) {
      el.addEventListener('click', function() {
        event.preventDefault()
        Velocity(document.getElementById(el.dataset["scrollTarget"]), "scroll", { duration: 900, easing: "ease-in-out" });

        var elems = document.querySelectorAll('[data-behavior~=scroll-trigger]');
        [].forEach.call(elems, function(el) {
            el.classList.remove("active");
        });

        el.classList.add("active");
      })
    })

    // scroll
    let mainNavLinks = document.querySelectorAll('[data-behavior~=scroll-trigger]');
    window.addEventListener("scroll", event => {
      let fromTop = window.scrollY;

      mainNavLinks.forEach(link => {
        let section = document.getElementById(link.getAttribute('data-scroll-target'));
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("active");
        } else {
          link.classList.remove("active");
        }
      });
    });

  }
};

export default scroll;
