const accordion = {
  init() {

      [].forEach.call(document.querySelectorAll('[data-behavior~=accordion-target]'), function(el) {

        el.addEventListener('click', function() {
          var itemBody = el.parentNode.querySelector('[data-behavior~=accordion-body]');

          if (itemBody.style['display'] != 'none') {
            var slideDir = 'slideUp';
            el.classList.remove("expanded");
          } else {
            var slideDir = 'slideDown';
            el.classList.add("expanded");
          }

          Velocity(itemBody, slideDir, {duration: 400, easing: 'ease-in-out' })
        })

      })

  }
};

export default accordion;

