const stickyNav = {
  init() {
    window.onscroll = function() {showStickyMenu()};
  }

};

function showStickyMenu() {

  var subnavigation = document.getElementById("subnavigation");
  if (subnavigation) {
    var sticky = subnavigation.offsetTop;
    if (window.pageYOffset > sticky) {
      subnavigation.classList.add("sticky-nav");
    } else {
      subnavigation.classList.remove("sticky-nav");
    }
  }
}

export default stickyNav;
