import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'

const icons = {
  init() {
    library.add(faInstagram)
    library.add(faTwitter)
    library.add(faYoutube)
    library.add(faLinkedin)
    library.add(faFacebook)
    library.add(faChevronLeft)
    library.add(faChevronDown)
    library.add(faGlobe)
    library.add(faCheck)
    dom.watch()
  }
};

export default icons;
