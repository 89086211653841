import 'swiper/dist/css/swiper.min.css'
import Swiper from 'swiper';

const testimonials = {
  init() {

    if (document.querySelector(".swiper-container") !== null) {
      new Swiper('.swiper-container', {
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        speed: 900,
        effect: 'fade',
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      });
    }

  }
};

export default testimonials;
