/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
//
require("rails-ujs").start()
require("turbolinks").start()

// Javascript
require('velocity-animate');

import icons from './icons';
import testimonials from './testimonials';
import modal from './modal';
import highlight from './highlight';
import accordion from './accordion';
import mobileNav from './mobileNav';
import stickyNav from './stickyNav';
import scroll   from './scroll';
import confettis   from './confettis';

document.addEventListener('turbolinks:load', function() {
  icons.init();
  testimonials.init();
  modal.init();
  highlight.init();
  accordion.init();
  mobileNav.init();
  stickyNav.init();
  scroll.init();
  confettis.init();
});

// Stylesheets
require('stylesheets/application.scss');
