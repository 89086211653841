const modal = {
  init() {

    var open_modal_selector = "[data-behavior~=open-modal]"
    var close_modal_selector = "[data-behavior~=close-modal]"

    if (document.querySelector(open_modal_selector) !== null) {

      // Add open-modal listeners
      [].forEach.call(document.querySelectorAll(open_modal_selector), function(el) {
        el.addEventListener('click', function(event) {
          event.preventDefault();
          open_modal(el.dataset["modalId"]);
        })
      });

      // Add close-modal listeners
      [].forEach.call(document.querySelectorAll(close_modal_selector), function(el) {
        el.addEventListener('click', function(event) {
          event.preventDefault();
          // FIXME: DIRECT DEPENDENCY ON app/javascript/confettis.js
          //window.confetti.stop();
          close_modal();
        })
      });

      document.body.addEventListener('keydown', function(e) {
        const keyCode = e.keyCode || e.which;

        if (keyCode === 27) {
          return close_modal();
        }
      });

      // Functions
      var open_modal = function(modal_id) {
        document.getElementById(modal_id).classList.remove('out');
        document.getElementById(modal_id).classList.add('animate');
        document.body.classList.add('modal-active');
      };

      var close_modal = function() {
        [].forEach.call(document.querySelectorAll('.modal-container'), function(el) {
          el.classList.add('out');
          el.classList.remove('animate');
        });
        document.body.classList.remove('modal-active');
      };

    } // end
  }
};

export default modal;
