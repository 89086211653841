import hljs from 'highlight.js/lib/highlight.js';
import ruby from 'highlight.js/lib/languages/ruby.js';
import css from 'highlight.js/lib/languages/css.js';
import html from 'highlight.js/lib/languages/xml.js';
import javascript from 'highlight.js/lib/languages/javascript.js';

const highlight = {
  init() {
    hljs.registerLanguage('ruby', ruby);
    hljs.registerLanguage('css', css);
    hljs.registerLanguage('html', html);
    hljs.registerLanguage('javascript', javascript);
    [].forEach.call(document.querySelectorAll('pre code'), function(block) {
      hljs.highlightBlock(block);
    });
  }
};

export default highlight;
