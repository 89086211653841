const mobileNav = {
  init() {
    let isOpen = false;

    const openNav = function() {
      document.getElementById("mobileNav").style.height = "100%";
      isOpen = true;
    }

    const closeNav = function() {
      document.getElementById("mobileNav").style.height = "0%";
      isOpen = false;
    }

    const toggleButtons = document.querySelectorAll(".js-menu-toggle")

    for(let i=0; i<toggleButtons.length; i++) {
      toggleButtons[i].addEventListener('click', function() {
        if (isOpen) {
          closeNav();
        } else {
          openNav();
        }
      });
    }
  }
};


export default mobileNav;
